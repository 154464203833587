"use client"

import { Button, Icons, InputWithLabel, Pill } from "shared/ui"
import styles from "app/(static)/home/sections/cover-section/ui/search-box/search-box.module.scss"
import { useRouter } from "next/navigation"
import Link from "next/link"
import { KeyboardEvent, useState } from "react"
import { catalogTree } from "entity/catalog-brand"
import { openDigiSearch } from "shared/lib/digisearch"

export const SearchBox = () => {
  const router = useRouter()
  const [search, setSearch] = useState<string>("")

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSubmit()
    }
  }

  const onSubmit = () => {
    if (search.trim()) {
      router.push(`/products/?search=${search.trim()}`)
    }
  }

  const { title, href, ...subcategoriesRecord } = catalogTree.sneakers
  const subcategories = Object.values(subcategoriesRecord)

  return (
    <div className={styles.search_box}>
      <div className={styles.title}>Здесь найдется любой товар</div>
      <div className={styles.search_input_group} onClick={openDigiSearch}>
        <InputWithLabel
          className={styles.input}
          placeholder="Введите название бренда или модели"
          prefix={<Icons.Search />}
          size="large"
          name="search"
          noOutline
          onChange={onChangeSearch}
          onKeyDown={onKeyDown}
        />
        <Button size="large" type="accent" className={styles.button} onClick={onSubmit} htmlType="submit">
          Найти
        </Button>
      </div>
      <div className={styles.brand_box}>
        <div className={styles.label}>Популярные бренды</div>
        <div className={styles.brand_list}>
          {subcategories.map((brand) => (
            <Link key={brand.href} href={brand.href} className={styles.brand}>
              <Pill>{brand.title}</Pill>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
